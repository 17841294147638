import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const TypeBar = observer(({ isOpen, setIsOpen }) => {
  return (
    <Button
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ backgroundColor: "#289b41", color: "white" }}
      onClick={() => setIsOpen(!isOpen)}
      startIcon={<MenuIcon />}
    />
  );
});

export default TypeBar;
