import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdAddShoppingCart } from "react-icons/md";
import styles from "../components/DeviceItemCard.module.scss";
import DeviceRating from "./DeviceRating";
import axios from "axios";
import { useCart } from "../CartContext";
import { useTranslation } from "react-i18next";

const DeviceItem = ({ device }) => {
  const { addToCart } = useCart();
  const { t, i18n } = useTranslation("global");
  const [rating, setRating] = useState(device.rating);

  const handleRatingChange = async (newRating) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/rating`,
        {
          deviceId: device.id,
          rating: newRating,
        }
      );
      console.log("Рейтинг успешно отправлен на сервер:", response.data);

      const updatedRating = await fetchDeviceRating(device.id);
      setRating(updatedRating);
    } catch (error) {
      console.error("Ошибка при отправке рейтинга на сервер:", error);
    }
  };

  const fetchDeviceRating = async (deviceId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/rating/${deviceId}`
      );
      return response.data.rating;
    } catch (error) {
      console.error("Ошибка при загрузке рейтинга с сервера:", error);
      return rating;
    }
  };

  // Функция для получения названия устройства на текущем языке
  const getDeviceName = () => {
    const translations = device.translations ? { ...device.translations } : {};
  
    // Возвращаем название на выбранном языке, если оно доступно
    return (
      translations[i18n.language] || // Название на текущем языке
      translations['hy'] ||          // Запасное название на армянском
      translations['ru'] ||          // Запасное название на русском
      translations['en'] ||          // Запасное название на английском
      device.name                    // Общее название, если переводов нет
    );
  };


  const getLinkPath = () => {
    const languagePrefix = i18n.language === "hy" ? "" : `${i18n.language}/`;
    return `/${languagePrefix}ankoxnayin-paraga/${device.id}/${getDeviceName()}`;
  };

  return (
    <div className={`${styles.card} card`}>
      <div className={styles.imageDiv}>
        <Link to={getLinkPath()} replace={false}>
        <img
  src={device?.img && device.img[0] ? `${process.env.REACT_APP_API_URL}${device.img[0].path}` : "\client\src\img\default img.jpg"}
  alt={getDeviceName()}
  className={styles.deviceImage}
/>
        </Link>
        <div
          className={styles.bottom}
          onClick={() => addToCart(device)}>
          <div className={styles.content}>
            <div productid={device.id}>
              <MdAddShoppingCart />
              <div>{t("Global.Basket")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardContent}>
      <Link to={getLinkPath()} className={styles.title}>
  {getDeviceName()}
</Link>
        <DeviceRating
          value={rating}
          onChange={handleRatingChange}
        />
        <div className={styles.rating}>
          {t("Global.Rating")}: {rating}
        </div>
        <p className={styles.price}>
          {device.price} ֏
        </p>
      </div>
    </div>
  );
};

export default DeviceItem;
