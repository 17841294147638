import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import DeviceItem from "./DeviceItem";
import "./Style.css";

const DeviceList = observer(() => {
  const { device } = useContext(Context);

  return (
    <div
      className={"row-custom"}
      style={{
        justifyContent: "stretch",
        alignItems: "stretch",
        flexWrap: "wrap",
        marginTop: 10,
      }}>
      {device.devices.map((device) => (
        <DeviceItem
          key={device.id}
          device={device}
        />
      ))}
    </div>
  );
});

export default DeviceList;
