import React, { useContext } from "react";
import DeviceItem from "../../components/DeviceItem";
import { observer } from "mobx-react-lite";
import { Context } from "../..";

const SimilarDeviceList = observer(() => {
  const { device } = useContext(Context);

  return (
    <div
      className="row-custom"
      style={{ flexWrap: "wrap" }}>
      {device.devices.map((device) => (
        <DeviceItem
          key={device.id}
          device={device}
        />
      ))}
    </div>
  );
});

export default SimilarDeviceList;
