import React, { memo } from "react";
import styles from "./Styles.module.scss";

const images = [
  require("../../../assets/image1.jpeg"),
  require("../../../assets/image2.jpeg"),
  require("../../../assets/image3.jpeg"),
  require("../../../assets/image4.jpeg"),
  require("../../../assets/image5.jpeg"),
  require("../../../assets/image6.jpeg"),
  require("../../../assets/image7.jpeg"),
];

const Gallery = () => {
  return (
    <div className={styles.main}>
      <div className={"wrapper"}>
        <div className={styles.gallery}>
          {images.map((image, index) => (
            <div
              className={styles["gallery-item"]}
              key={index}>
              <img
                src={image}
                alt={`Gallery ${index + 1}`}
                className={styles["gallery-image"]}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Gallery);
