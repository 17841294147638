import React from "react";
import styles from "./Product/Styles.module.scss";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import image1 from "../assets/image1.jpeg";
import image2 from "../assets/image2.jpeg";
import image3 from "../assets/image3.jpeg";
import image4 from "../assets/image4.jpeg";
import image5 from "../assets/image5.jpeg";
import image6 from "../assets/image6.jpeg";
import image7 from "../assets/image7.jpeg";
import image8 from "../assets/image8.jpeg";

const sliderItems = [
  [image1, image2, image3, image4],
  [image5, image6, image7, image8],
];

const About = () => {
  const { t } = useTranslation("global");
  return (
    <div className={styles.About}>
      <h2>{t("AboutUs.about_us")}</h2>
      <p>{t("AboutUs.text")}</p>

      <div className={styles.Addresses}>
        <div>
          <p>{t("Basket.Address")} 1</p>
          <p>{t("AboutUs.Azatamartikneri")} 59</p>
        </div>
        <div>
          <p>{t("Basket.Address")} 2</p>
          <p>{t("AboutUs.Norashen")} </p>
        </div>
      </div>

      <Swiper
        style={{ height: "600px" }}
        className={styles.swiperImage}>
        {sliderItems.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={styles.sliderWrapper}>
              {item.map((image, idx) => (
                <div
                  className={styles.sliderImages}
                  key={idx}>
                  <img
                    src={image}
                    alt={image}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div>
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default About;
