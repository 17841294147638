import React, { useContext, useEffect } from "react";
import BrandBar from "../../components/BrandBar";
import DeviceList from "../../components/DeviceList";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { fetchBrends, fetchDevice, fetchTypes } from "../../http/deviceAPI";
import Pages from "../../components/Pages";
import BigSlider from "../../components/BigSlider";
import Header from "./sections/Header/Header";

const Shop = observer(() => {
  const { device } = useContext(Context);

  useEffect(() => {
    fetchTypes().then((data) => device.setTypes(data));
    fetchBrends().then((data) => device.setBrends(data));
    fetchDevice(null, null, 1, 16).then((data) => {
      device.setDevices(data.rows);
      device.setTotalCount(data.count);
    });
  }, [device]);

  useEffect(() => {
    if (device.selectedType && device.selectedBrend) {
      fetchDevice(
        device.selectedType.id,
        device.selectedBrend.id,
        device.page,
        16
      ).then((data) => {
        device.setDevices(data.rows);
        device.setTotalCount(data.count);
      });
    }
  }, [device.page, device.selectedType, device.selectedBrend, device]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={"wrapper"}>
      <Header />
      <BigSlider />
      <div>
        <BrandBar />
        {/* <Col md={2}></Col>
        <Col md={18}> */}
        <DeviceList />
        <Pages />
        {/* </Col> */}
      </div>
    </div>
  );
});

export default Shop;
