import { Grid } from "@mui/material";
import React from "react";
import logo from "../../img/Beddinggreen.png";
import styles from "./FooterStyles.module.scss";
import { IoCallOutline } from "react-icons/io5";
import List from "./List";
import payments from "../../img/payments.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t, i18n } = useTranslation("global");
  return (
    <div className={"wrapper"}>
      <div className={styles.main}>
        <Grid
          container={true}
          className={styles.footerGrig}
          spacing={2}>
          <Grid
            item
            sm={6}
            lg={4}
            className={styles.grid1}
            paddingX={"20px"}>
            <img
              className={styles.logo}
              src={logo}
              alt="logo"
            />
            <p>{t("Footer.BeddingShopPresents")}</p>
            <div className={styles.call}>
              <IoCallOutline />
              <h5>
                {t("Footer.HaveAQuestionCallUs")}
                <br />
                <span>+374 99 111 040</span>
              </h5>
            </div>
          </Grid>
          {/* <Grid
            item
            sm={1}
            lg={3}
            className={styles.grids}
            paddingX={"16px"}> */}
          <List
            title=""
            content={[
              <Link
                to={`${i18n.language === "hy" ? "" : i18n.language}/mer-masin`}>
                {t("AboutUs.about_us")}
              </Link>,
              <Link
                to={`${
                  i18n.language === "hy" ? "" : i18n.language
                }/contact-us`}>
                {t("Global.ContactUs")}
              </Link>,
            ]}
          />
          {/* </Grid> */}
        </Grid>
        <hr />
      </div>
      <hr />
      <div className={styles.bottom}>
        <p>
          Copyright © 2016-2024 Beddingshop.am. All Rights Reserved. CREATED BY{" "}
          <a
            href="https://api.whatsapp.com/send?phone=37477781160"
            target="_blank"
            rel="noopener noreferrer">
            KraftCoding
          </a>
        </p>
        <img
          src={payments}
          alt="payment"
        />
      </div>
    </div>
  );
};

export default Footer;
