import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { Context } from "../../index";
import { createDevice, fetchBrends, fetchTypes } from "../../http/deviceAPI";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const CreateDevice = observer(({ show, onHide }) => {
  const { device } = useContext(Context);
  const { i18n } = useTranslation("global");
  const [name, setName] = useState({ hy: "", en: "", ru: "" });
  const [price, setPrice] = useState(0);
  const [files, setFiles] = useState(null);
  const [info, setInfo] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetchTypes().then((data) => device.setTypes(data));
    fetchBrends().then((data) => device.setBrends(data));
  }, [device]);

  const addInfo = () => {
    setInfo([...info, { title: "", description: "", number: Date.now() }]);
  };

  const removeInfo = (number) => {
    setInfo(info.filter((i) => i.number !== number));
  };

  const changeInfo = (key, value, number) => {
    setInfo(
      info.map((i) => (i.number === number ? { ...i, [key]: value } : i))
    );
  };

  const selectFiles = (e) => {
    setFiles(e.target.files); // Allows multiple file selection
  };

  const addDevice = async () => {
    const formData = new FormData();

    // Append files if they exist
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("img", files[i]); // Make sure this matches your server-side upload handler
      }
    }
    console.log("📢 [CreateDevice.js:49]", formData.get("img"));

    // Append other device information
    formData.append("price", price);
    formData.append("brendId", device.selectedBrend.en.brendId);
    formData.append("typeId", device.selectedType.en.typeId); // Ensure you are using selectedType here

    // Append translations
    formData.append(
      "translations",
      JSON.stringify(
        Object.entries(name).map(([lang, value]) => ({
          language: lang,
          name: value,
        }))
      )
    );

    // Append additional info
    if (info.length > 0) {
      formData.append("info", JSON.stringify(info));
    }

    try {
      // Call the createDevice API function to send data
      await createDevice(formData);
      setSuccessMessage("Device successfully added.");
      resetForm();
      onHide();
    } catch (error) {
      console.error("Failed to add device:", error);
    }
  };

  const resetForm = () => {
    setName({ hy: "", en: "", ru: "" });
    setPrice(0);
    setFiles(null);
    setInfo([]);
    device.setSelectedType({});
    device.setSelectedBrend({});
    setTimeout(() => setSuccessMessage(""), 3000); // Clear message after 3 seconds
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Добавить устройство</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Dropdown className="mt-2 mb-2">
            <Dropdown.Toggle>
              {device?.selectedType?.[i18n.language]?.name || "Выберите тип"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {device.types.map((type) => (
                <Dropdown.Item
                  onClick={() => device.setSelectedType(type.translations)}
                  key={type.id}>
                  {type.translations?.[i18n.language]?.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="mt-2 mb-2">
            <Dropdown.Toggle>
              {device.selectedBrend?.[i18n.language]?.name || "Выберите бренд"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {device.brends.map((brend) => (
                <Dropdown.Item
                  onClick={() => device.setSelectedBrend(brend.translations)}
                  key={brend.id}>
                  {brend?.translations?.[i18n.language]?.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {/* Translation Inputs */}
          <h5>Translations</h5>
          {["hy", "en", "ru"].map((lang) => (
            <Form.Control
              key={lang}
              value={name[lang]}
              onChange={(e) => setName({ ...name, [lang]: e.target.value })}
              className="mt-3"
              placeholder={`Введите название устройства на ${lang.toUpperCase()}`}
            />
          ))}
          <Form.Control
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            className="mt-3"
            placeholder="Введите стоимость устройства"
            type="number"
          />
          <Form.Control
            className="mt-3"
            type="file"
            onChange={selectFiles}
            multiple
          />

          <hr />
          <Button
            variant="outline-dark"
            onClick={addInfo}>
            Добавить новое свойство
          </Button>
          {info.map((i) => (
            <Row
              className="mt-4"
              key={i.number}>
              <Col md={4}>
                <Form.Control
                  value={i.title}
                  onChange={(e) =>
                    changeInfo("title", e.target.value, i.number)
                  }
                  placeholder="Введите название свойства"
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  value={i.description}
                  onChange={(e) =>
                    changeInfo("description", e.target.value, i.number)
                  }
                  placeholder="Введите описание свойства"
                />
              </Col>
              <Col md={4}>
                <Button
                  onClick={() => removeInfo(i.number)}
                  variant="outline-danger">
                  Удалить
                </Button>
              </Col>
            </Row>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {successMessage ? (
          <p className="text-success">{successMessage}</p>
        ) : (
          <>
            <Button
              variant="outline-danger"
              onClick={onHide}>
              Закрыть
            </Button>
            <Button
              variant="outline-success"
              onClick={addDevice}
              disabled={
                !device.selectedType?.en?.typeId ||
                !device.selectedBrend?.en?.brendId
              }>
              Добавить
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
});

export default CreateDevice;
