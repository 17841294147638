import { useEffect } from "react";

const YandexMetrika = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://mc.yandex.ru/metrika/tag.js";
    script.async = true;

    script.onload = () => {
      window.ym =
        window.ym ||
        function () {
          (window.ym.a = window.ym.a || []).push(arguments);
        };
      window.ym(97286761, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default YandexMetrika;
