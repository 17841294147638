import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import { useParams } from "react-router-dom";
import { fetchDevice } from "../http/deviceAPI";
import DeviceItem from "./DeviceItem";
import Pages from "./Pages";
import styles from "./ProductsByTypePage.module.css";

const ProductsByTypePage = observer(() => {
  const { device } = useContext(Context);
  const { typeId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDevice(
          typeId,
          null,
          device.page,
          device.limit
        );
        device.setDevices(response.rows);
        device.setTotalCount(response.count);
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };

    fetchData();
  }, [typeId, device.page, device.limit, device]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [device.page]);

  return (
    <div>
      {device.selectedType?.name && (
        <h2 className={styles.pageHeader}>{device.selectedType?.name}</h2>
      )}
      <div className="wrapper">
        <div className={`row-custom ${styles.deviceRow}`}>
          {device.devices
            .filter((device) => device.typeId === Number(typeId))
            .map((filteredDevice) => (
              <DeviceItem
                key={filteredDevice.id}
                device={filteredDevice}
              />
            ))}
        </div>
        <Pages />
      </div>
    </div>
  );
});

export default ProductsByTypePage;
