import React from "react";
import Carousel from "react-bootstrap/Carousel";
import img from "../assets/bigslider 1.jpg";
import img2 from "../assets/bigslider 2.jpg";
import img3 from "../assets/bigslider 3.jpg";
import { useTranslation } from "react-i18next";

const BigSlider = () => {
  const { t } = useTranslation("global");
  return (
    <div className="carousel-container">
      <Carousel>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100 .img-fluid."
            src={img2}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>{t("Global.Bedding")}</h3>
            <p>{t("Global.CarouselText1")}</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100 .img-fluid."
            src={img}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>{t("Global.Bedding")}</h3>
            <p>{t("Global.CarouselText2")}</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100 .img-fluid."
            src={img3}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>{t("Global.Bedding")}</h3>
            <p>{t("Global.CarouselText3")}</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default BigSlider;
