import React, { useContext, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { login, registration } from "../http/userAPI";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import { useTranslation } from "react-i18next";

const Auth = observer(() => {
  const { t, i18n } = useTranslation("global");
  const { user } = useContext(Context);
  const location = useLocation();
  const lang = i18n.language === "hy" ? "" : `/${i18n.language}`;
  const isLogin = location.pathname === `${lang}/login`;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const click = async () => {
    try {
      let data;
      if (isLogin) {
        data = await login(email, password);
      } else {
        data = await registration(email, password);
      }
      user.setUser(user);
      user.setIsAuth(true);

      const langPrefix = i18n.language === "hy" ? "" : `/${i18n.language}`;
      const path = `${langPrefix}/`;

      navigate(path);
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center wrapper"
      style={{ height: window.innerHeight - 54 }}>
      <Card
        className="p-5"
        style={{ width: "400px" }}>
        <h2 className="m-auto">
          {isLogin ? t("Auth.SignIn") : t("Auth.SignUp")}
        </h2>
        <Form className="d-flex flex-column">
          <Form.Control
            className="m-0 mt-3"
            placeholder={t("Auth.UsernameOrEmailAddress")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Control
            className="mt-3"
            placeholder={t("Auth.Password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />

          <Row className="mt-3 pl-3 pr-3 mw-100 align-self-center">
            {isLogin ? (
              <div>
                {t("Auth.Don'tHaveARegistration")}{" "}
                <NavLink
                  to={`${lang}/registration`}
                  replace>
                  {t("Auth.Register")}
                </NavLink>
              </div>
            ) : (
              <div>
                {t("Auth.DoYouHaveARegistration")}{" "}
                <NavLink
                  to={`${lang}/login`}
                  replace>
                  {t("Auth.SignIn")}
                </NavLink>
              </div>
            )}
            <Button
              onClick={click}
              className={"w-100 mw-100"}
              variant={"outline-success"}>
              {isLogin ? t("Auth.SignIn") : t("Auth.SignUp")}
            </Button>
          </Row>
        </Form>
      </Card>
    </div>
  );
});

export default Auth;
