import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import styles from "./../Product/Styles.module.scss";
import { Breadcrumbs, Grid, Link as BCLink } from "@mui/material";
import { AddShoppingCart, NavigateNext } from "@mui/icons-material";
import { HiMinus, HiPlus } from "react-icons/hi";
import {
  FaFacebookF,
  FaInstagram,
  FaTelegram,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { fetchDevicesByType, fetchOneDevice } from "../../http/deviceAPI";
import DeviceRating from "../../components/DeviceRating";
import axios from "axios";
import { useCart } from "../../CartContext";
import SimilarDeviceList from "./SimilarDeviceList";
import { useTranslation } from "react-i18next";

const DevicePage = () => {
  const { t, i18n } = useTranslation("global");
  const { addToCart } = useCart();
  const [device, setDevice] = useState({ info: [] });
  const [deviceRating, setDeviceRating] = useState(0);

  const { id } = useParams();
  const navigate = useNavigate();

  const fetchDevice = async (deviceId) => {
    try {
      const data = await fetchOneDevice(deviceId);
      setDevice(data);
    } catch (error) {
      console.error("Ошибка при получении данных о товаре:", error);
    }
  };

  useEffect(() => {
    fetchDevice(id);
  }, [id]);

  const handleFacebookClick = () => {
    window.open("https://www.facebook.com/bedder.shop", "_blank");
  };

  const handleTelegramClick = () => {
    window.open("https://t.me/BeddingShopam", "_blank");
  };

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/beddingshop.am/", "_blank");
  };

  const handleYoutubeClick = () => {
    window.open("https://www.youtube.com/@BeddingShopam/featured", "_blank");
  };

  const handleWhatsAppClick = () => {
    window.open("https://api.whatsapp.com/send?phone=37499111040", "_blank");
  };

  const [imageIndex, setImageIndex] = useState(0);
  const [amount, setAmount] = useState(1);
  const [bgPosition] = useState("50% 50%");

  useEffect(() => {
    fetchOneDevice(id).then((data) => setDevice(data));
  }, [id]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const newData = await fetchOneDevice(id);
        setDevice(newData);
        window.scrollTo(0, 0);
      } catch (error) {
        console.error("Ошибка при загрузке данных о товаре:", error);
      }
    };

    loadData();
  }, [id]);

  const handleClick = (event) => {
    event.preventDefault();

    navigate(event.target.pathname);
  };

  const changeMainImage = useCallback((ind) => {
    setImageIndex(ind);
  }, []);

  const changeAmount = (qty) => {
    if (qty + amount >= 1) {
      setAmount((prevState) => prevState + qty);
    }
  };

  const zoom = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    e.target.style.backgroundPosition = `${x}% ${y}%`;
  };

  useEffect(() => {
    const myElement = document.getElementById("myElement");
    if (myElement) {
      myElement.addEventListener("mousemove", zoom);
    }

    return () => {
      if (myElement) {
        myElement.removeEventListener("mousemove", zoom);
      }
    };
  }, []);

  const handleRatingChange = async (newRating) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/rating`,
        {
          deviceId: device?.id,
          rating: newRating,
        }
      );

      console.log("Рейтинг успешно отправлен на сервер:", response.data);

      const updatedRating = await fetchDeviceRating(device?.id);

      setDeviceRating(updatedRating);
    } catch (error) {
      console.error("Ошибка при отправке рейтинга на сервер:", error);
    }
  };

  const fetchDeviceRating = async (deviceId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/rating/${deviceId}`
      );
      return response.data.rating;
    } catch (error) {
      console.error("Ошибка при загрузке рейтинга с сервера:", error);
      return deviceRating;
    }
  };

  useEffect(() => {
    const loadInitialRating = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/rating/${id}`
        );
        const initialRating = response.data.rating;
        setDeviceRating(initialRating);
      } catch (error) {
        console.error("Ошибка при загрузке начального рейтинга:", error);
      }
    };

    loadInitialRating();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [similarDevices, setSimilarDevices] = useState([]);

  useEffect(() => {
    const fetchSimilarDevices = async () => {
      if (device && device.typeId) {
        try {
          const similarDevicesData = await fetchDevicesByType(device.typeId);
          setSimilarDevices(similarDevicesData);
        } catch (error) {
          console.error("Failed to fetch similar devices:", error);
        }
      }
    };

    fetchSimilarDevices();
  }, [device]);

  // const image = useMemo(() => {
  //   if (device.img) {
  //     try {
  //       return JSON.parse(device.img);
  //     } catch (error) {
  //       console.error("Error parsing device images:", error);
  //       return []; // Return an empty array on parse error
  //     }
  //   }
  //   return []; // Return an empty array if device.img is undefined
  // }, [device.img]);

  // useEffect(() => {
  //   changeMainImage(0);
  // }, [changeMainImage, image]);
  const image = useMemo(() => {
    if (device.img) {
      // Проверяем, является ли img строкой, и выполняем парсинг только если это необходимо
      if (typeof device.img === 'string') {
        try {
          return JSON.parse(device.img);
        } catch (error) {
          console.error("Error parsing device images:", error);
          return []; // Возвращаем пустой массив в случае ошибки парсинга
        }
      }
      // Если img уже объект или массив, возвращаем его напрямую
      return device.img;
    }
    return []; // Возвращаем пустой массив, если img отсутствует
  }, [device.img]);
  
  useEffect(() => {
    changeMainImage(0);
  }, [changeMainImage, image]);
  

  return (
    <div>
      <div className={"wrapper"}>
        <div
          role="presentation"
          onClick={handleClick}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
            sx={{ p: "14px 0" }}>
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="black"
              href={`${i18n.language === "hy" ? "" : i18n.language}/`}
              fontSize={"14px"}>
              {t("Global.Main")}
            </BCLink>
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="#289b41"
              href={`${i18n.language === "hy" ? "" : i18n.language}/basket`}
              aria-current="page"
              fontSize={"14px"}>
              {t("Basket.ShoppingCart")}
            </BCLink>
          </Breadcrumbs>
        </div>
        {console.log(device)}
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "center" }}>
          <Grid
            item
            md={6}
            className={styles.left}>
            <div className={styles.images}>
              {image.map((img, ind) => (
                <img
                  onClick={() => changeMainImage(ind)}
                  className={
                    img === imageIndex ? styles.activeImage : styles.notActive
                  }
                  src={process.env.REACT_APP_API_URL + img.path}
                  key={img.id}
                  alt={"images"}
                />
              ))}
            </div>
            <div
              className={styles.mainImage}
              onMouseMove={zoom}
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_API_URL + image[imageIndex]?.path
                })`,
                backgroundPosition: bgPosition,
              }}>
              <img
                src={process.env.REACT_APP_API_URL + image[imageIndex]?.path}
                alt="mainImage"
              />
            </div>
          </Grid>
          <Grid
            item
            md={6}>
            <div className={styles.right}>
              <h1>{device?.name}</h1>
              <div className={styles.rate}>
                <DeviceRating
                  value={deviceRating}
                  onChange={handleRatingChange}
                />
                <p>
                  ({deviceRating} {t("Global.Rating")})
                </p>

                {/*                     {device.info.map((info, index) =>*/}
                {/*<Row key={info.id} style={{background: index % 2 === 0 ? 'lightgray' : 'transparent', padding: 10}}>*/}
                {/*    {info.title}: {info.description}*/}
                {/*</Row>*/}
                {/*)}*/}
              </div>

              <p className={styles.price}> ֏ {device?.price}</p>
              {device?.info.map((info, index) => (
                <p
                  className={styles.description}
                  key={info?.id}>
                  {" "}
                  {info?.title} {info?.description}
                </p>
              ))}
              <div className={styles.quantity}>
                <div>
                  <button onClick={(event) => changeAmount(-1)}>
                    <HiMinus />
                  </button>
                  <input
                    type={"number"}
                    min={1}
                    value={amount}
                    readOnly
                  />
                  <button onClick={(event) => changeAmount(+1)}>
                    <HiPlus />
                  </button>
                </div>
              </div>
              <div className={styles.buttons}>
                <button
                  className={styles.addToCardBtn}
                  onClick={() => addToCart(device)}
                  id={device?.id}>
                  <AddShoppingCart />
                  {t("Global.Basket")}
                </button>
              </div>
              <div className={styles.end}>
                <div className={styles.socials}>
                  <p>{t("Global.SocialNetworks")}</p>
                  <div className={styles.socialsIcon}>
                    <div onClick={handleFacebookClick}>
                      <FaFacebookF />
                    </div>
                    <div onClick={handleTelegramClick}>
                      <FaTelegram />
                    </div>
                    <div onClick={handleInstagramClick}>
                      <FaInstagram />
                    </div>
                    <div onClick={handleYoutubeClick}>
                      <FaYoutube />
                    </div>
                    <div onClick={handleWhatsAppClick}>
                      <FaWhatsapp />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className={styles.similar}>{t("Global.SimilarProducts")}</div>

        <SimilarDeviceList
          devices={similarDevices}
          // onNavigate={() =>
          //   handleNavigateToSimilarProduct(device.id, device.name)
          // }
        />
      </div>
    </div>
  );
};

export default React.memo(DevicePage);
