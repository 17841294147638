import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "./Style.css";
import styles from "./Header.module.css";
import SideBanner from "./SideBanner";
import HeaderBanner from "./HeaderBanner";

const Header = () => {
  return (
    <div className={styles.header}>
      <HeaderBanner />

      <SideBanner />
    </div>
  );
};

export default Header;
