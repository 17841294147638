import React, { useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import CreateBrend from "../components/modals/CreateBrend";
import CreateDevice from "../components/modals/CreateDevice";
import CreateType from "../components/modals/CreateType";
import { Context } from "../index";
import { CardMedia, MenuItem } from "@mui/material";
import { MdAddShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styles from "../components/DeviceItemCard.module.scss";

import {
  deleteOne,
  fetchBrends,
  fetchDevice,
  fetchTypes,
} from "../http/deviceAPI";
import { deleteOneType } from "../http/deviceAPI";
import { deleteOneBrand } from "../http/deviceAPI";
// import EditDevice from "../components/modals/EditDevice";
import { useTranslation } from "react-i18next";

const Admin = () => {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();

  const { device } = useContext(Context);
  const [brendVisible, setBrendVisible] = useState(false);
  const [typeVisible, setTypeVisible] = useState(false);
  const [deviceVisible, setDeviceVisible] = useState(false);
  // const [editDeviceVisible, setEditDeviceVisible] = useState(false);
  // const [currentDevice, setCurrentDevice] = useState(null);

  useEffect(() => {
    fetchTypes().then((data) => device.setTypes(data));
    fetchBrends().then((data) => device.setBrends(data));
    fetchDevice(null, null, 1, 16).then((data) => {
      device.setDevices(data.rows);
      device.setTotalCount(data.count);
    });
  }, [device]);

  useEffect(() => {
    if (device.selectedType && device.selectedBrend) {
      fetchDevice(
        device.selectedType.id,
        device.selectedBrend.id,
        device.page,
        16
      ).then((data) => {
        device.setDevices(data.rows);
        device.setTotalCount(data.count);
      });
    }
  }, [device.page, device.selectedType, device.selectedBrend, device]);

  const goHome = (id, name) => {
    // Construct the base path, checking if the language is already part of the URL.
    const languagePath = i18n.language === "hy" ? "" : `/${i18n.language}`;
    const path = `${languagePath}/ankoxnayin-paraga/${id}/${name}`;

    navigate(path, { replace: true });
    window.scrollTo({ top: 1010, behavior: "smooth" });
  };
  const deleteItems = (device) => {
    deleteOne(device.id);
  };

  const deleteType = async (type) => {
    try {
      const response = await deleteOneType(type.id);
      console.log(response);
    } catch (error) {
      console.error("Error deleting type:", error);
    }
  };

  const deleteBrend = async (brend) => {
    try {
      const response = await deleteOneBrand(brend.id);
      console.log(response);
    } catch (error) {
      console.error("Error deleting brend:", error.message);
      if (error.response) {
        console.error("Axios Status Code:", error.response.status);
      }
    }
  };
  // const openEditModal = (device) => {
  //   console.log("📢 [Admin.js:63]", JSON.stringify(device, null, 2));
  //   setCurrentDevice(device);
  //   setEditDeviceVisible(true);
  // };

  return (
    <>
      <div className="d-flex flex-column wrapper">
        <Button
          variant={"outline-dark"}
          className="mt-2"
          onClick={() => setTypeVisible(true)}>
          {t("Admin.AddACategory")}
        </Button>
        <Button
          variant={"outline-dark"}
          className="mt-2"
          onClick={() => setBrendVisible(true)}>
          {t("Admin.AddBrandQuality")}
        </Button>
        <Button
          variant={"outline-dark"}
          className="mt-2"
          onClick={() => setDeviceVisible(true)}>
          {t("Admin.AddAProduct")}
        </Button>

        <CreateBrend
          show={brendVisible}
          onHide={() => setBrendVisible(false)}
        />
        <CreateDevice
          show={deviceVisible}
          onHide={() => setDeviceVisible(false)}
        />
        <CreateType
          show={typeVisible}
          onHide={() => setTypeVisible(false)}
        />
        {/* <EditDevice
          show={editDeviceVisible}
          onHide={() => setEditDeviceVisible(false)}
          deviceToEdit={currentDevice}
        /> */}
      </div>
      <div className={"wrapper"}>
        <div className="badding-items-container">
          <div
            className={"row-custom"}
            style={{
              justifyContent: "stretch",
              alignItems: "stretch",
              flexWrap: "wrap",
              marginTop: 10,
            }}>
            {device.devices.map((device, index) => (
              <div
                key={index}
                style={{ boxShadow: "none", ...device.sty }}
                className={`${styles.card} card`}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                  className={styles.imageDiv}>
                  <CardMedia
                    component="img"
                    image={process.env.REACT_APP_API_URL + device?.img[0]?.path}
                    onClick={() => goHome(device.id, device.name)}
                    alt="green iguana"
                    sx={{ width: "100%" }}
                  />

                  <div>
                    <div className={styles.bottom}>
                      <div
                        className={styles.content}
                        style={{ flexWrap: "wrap" }}>
                        <div
                          productid={device.id}
                          onClick={() =>
                            deleteItems(JSON.parse(JSON.stringify(device)))
                          }>
                          <MdAddShoppingCart />
                          <h5 style={{ margin: 0, fontSize: 15 }}>
                            {t("Global.Delete")}
                          </h5>
                        </div>
                        {/* <div className={styles.rode}></div>
                        <Link onClick={() => openEditModal(device)}>
                          <GiBinoculars />
                          <h5 style={{ margin: 0, fontSize: 15 }}>
                            {t("Admin.Edit")}
                          </h5>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.cardContent}>
                  <div
                    onClick={() => goHome(device.id, device.name)}
                    className={styles.title}>
                    {device.translations?.[i18n.language]?.name}
                  </div>
                  <p
                    style={device?.children && { color: "#EF837B" }}
                    className={styles.price}>
                    ${device.price} {device?.children && device.children}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <h2 style={{ textAlign: "center" }}>{t("Admin.Category")}</h2>

        {device?.types.map((type) => (
          <div
            key={type.id}
            style={{ display: "flex", alignItems: "center" }}>
            <MenuItem style={{ cursor: "pointer" }}>
              {type?.translations?.[i18n.language]?.name}
            </MenuItem>
            <Button
              variant="danger"
              size="sm"
              productid={type.id}
              onClick={() => deleteType(type)}
              style={{ marginLeft: "10px" }}>
              {t("Global.Delete")}
            </Button>
          </div>
        ))}
        <h2 style={{ textAlign: "center" }}>{t("Admin.BrandQuality")}</h2>
        {device &&
          device.brends.map((brend) => (
            <Card
              style={{ cursor: "pointer", marginBottom: 10 }}
              key={brend.id}>
              <p style={{ textAlign: "center" }}>
                {brend.translations[i18n.language].name}
              </p>

              <Button
                variant="danger"
                size="sm"
                className="ml-2"
                productid={brend.id}
                onClick={() => deleteBrend(brend)}>
                {t("Global.Delete")}
              </Button>
            </Card>
          ))}
      </div>
    </>
  );
};

export default Admin;
