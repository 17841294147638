import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const languages = [
  { code: "hy", flag: require("../../assets/hy.png") },
  { code: "en", flag: require("../../assets/en.png") },
  { code: "ru", flag: require("../../assets/ru.png") },
];

const dropdownVariants = {
  open: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.3,
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: -20 },
};

const Language = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find((lang) => lang.code === i18n.language) || languages[0]
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useParams();

  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const defaultLanguage = "hy";

  const handleLanguageSelect = (language) => {
    i18n.changeLanguage(language.code);
    setSelectedLanguage(language);
    setIsOpen(false);

    const newPath =
      language.code === defaultLanguage
        ? location.pathname.replace(/^\/(en|hy|ru)/, "") || "/"
        : `/${language.code}${location.pathname.replace(/^\/(en|hy|ru)/, "")}`;
    navigate(newPath);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      if (i18n.language !== defaultLanguage) {
        i18n.changeLanguage(defaultLanguage);
        setSelectedLanguage(languages.find((l) => l.code === defaultLanguage));
      }
    } else {
      const pathLang = location.pathname.split("/")[1];
      if (pathLang && ["en", "ru"].includes(pathLang)) {
        if (i18n.language !== pathLang) {
          i18n.changeLanguage(pathLang);
          setSelectedLanguage(languages.find((l) => l.code === pathLang));
        }
      } else if (!pathLang || pathLang === defaultLanguage) {
        const pathWithoutLang =
          location.pathname.replace(/^\/(en|hy|ru)/, "") || "/";
        navigate(pathWithoutLang, { replace: true });
      }
    }
  }, [lang, defaultLanguage, i18n, location.pathname, navigate]);

  return (
    <div style={{ position: "relative", width: "fit-content" }}>
      <div
        onClick={toggleDropdown}
        style={{
          padding: "5px 10px",
          backgroundColor: "#fff",
          color: "#282828",
          borderRadius: "8px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}>
        <img
          src={selectedLanguage?.flag}
          alt={selectedLanguage?.code}
          style={{ width: "24px", height: "16px" }}
        />
        {selectedLanguage?.code.toUpperCase()}
      </div>

      <motion.div
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={dropdownVariants}
        style={{
          position: "absolute",
          top: "50px",
          left: 0,
          right: 0,
          backgroundColor: "#fff",
          borderRadius: "8px",
          overflow: "hidden",
          zIndex: 10,
        }}>
        {languages.map((language) => (
          <motion.div
            key={language.code}
            variants={itemVariants}
            onClick={() => handleLanguageSelect(language)}
            style={{
              padding: "5px 10px",
              color: "#282828",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            whileHover={{ backgroundColor: "#058397" }}>
            <img
              src={language.flag}
              alt={language.code}
              style={{ width: "24px", height: "16px" }}
            />
            {language.code.toUpperCase()}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default Language;
