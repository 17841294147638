import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { observer } from "mobx-react-lite";
import Footer from "./components/Footer/Footer";
import "./App.css";
import NevNavbar from "./components/NavBar/NewNavBar";
import Trait from "./components/Trait/Trait";
import LeftTypeBar from "./components/LeftTypeBar";
import YandexMetrika from "./YandexMetrika";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import Gallery from "./pages/Home/Gallery/Gallery";
import global_hy from "./translations/hy/global.json";
import global_en from "./translations/en/global.json";
import global_ru from "./translations/ru/global.json";

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  fallbackLng: "hy",
  detection: {
    lookupFromPathIndex: 0,
    checkWhitelist: true,
  },
  resources: {
    hy: {
      global: global_hy,
    },
    en: {
      global: global_en,
    },
    ru: {
      global: global_ru,
    },
  },
});

const App = observer(() => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <YandexMetrika />
        <NevNavbar
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        <LeftTypeBar
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        <AppRouter />
        <Trait />
        <Gallery />
        <Footer />
      </BrowserRouter>
    </I18nextProvider>
  );
});
export default App;
