import React, { useState } from "react";
import { createBrend } from "../../http/deviceAPI";
import { Button, Form, Modal } from "react-bootstrap";

const CreateBrend = ({ show, onHide }) => {
  const [name, setName] = useState({
    en: "",
    hy: "",
    ru: "",
  });

  const handleInputChange = (lang, value) => {
    setName((prev) => ({ ...prev, [lang]: value }));
  };

  const addBrend = () => {
    const translations = [
      { language: "en", name: name.en },
      { language: "hy", name: name.hy },
      { language: "ru", name: name.ru },
    ];

    // Ensure `name.en` is used as the main name.
    createBrend(
      name, // Default or main name is set to `name.en`
      translations
    )
      .then((data) => {
        setName({ en: "", hy: "", ru: "" });
        onHide();
        console.log("Brand added:", data);
      })
      .catch((error) => {
        console.error("Error adding brand:", error);
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить бренд
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            value={name.en}
            onChange={(e) => handleInputChange("en", e.target.value)}
            placeholder={"Enter brand name in English"}
          />
          <Form.Control
            value={name.hy}
            onChange={(e) => handleInputChange("hy", e.target.value)}
            placeholder={"Մուտքագրեք բրենդի անվանումը հայերեն"}
            className="mt-2"
          />
          <Form.Control
            value={name.ru}
            onChange={(e) => handleInputChange("ru", e.target.value)}
            placeholder={"Введите название бренда на русском"}
            className="mt-2"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={onHide}>
          Закрыть
        </Button>
        <Button
          variant="outline-success"
          onClick={addBrend}>
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateBrend;
