import React, { useContext } from "react";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import TypeBar from "../TypeBar";
import logo from "../../img/Bedding.png";
import styles from "./../NavBar/Styles.module.scss";
import { useCart } from "../../CartContext";
import { jwtDecode } from "jwt-decode";
import {
  FaFacebookF,
  FaInstagram,
  FaTelegramPlane,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Language from "../Language/Language";

const NevNavbar = observer(({ isOpen, setIsOpen }) => {
  const { t, i18n } = useTranslation("global");
  let token = localStorage.getItem("token");
  const { busketItems: myItems } = useCart();

  const navigate = useNavigate();
  const { user } = useContext(Context);

  const logOut = () => {
    user.setUser({});
    localStorage.removeItem("token");
    navigate(`${i18n.language === "hy" ? "" : i18n.language}/`);
    user.setIsAuth(false);
  };

  const admin_panel = () => {
    let path = `${i18n.language === "hy" ? "" : i18n.language}/admin`;
    navigate(path);
  };

  const basket_link = () => {
    let path = `${i18n.language === "hy" ? "" : i18n.language}/basket`;
    navigate(path);
  };

  const login = () => {
    let path = `${i18n.language === "hy" ? "" : i18n.language}/login`;
    navigate(path);
  };

  const handleSocialIconClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <AppBar
      position="static"
      className={styles["custom-app-bar"]}>
      <Toolbar className={styles["toolbar"]}>
        <div>
          <TypeBar
            className={styles.typebar}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />

          <Typography
            variant="h6"
            component="div"
            className={styles["logo-wrapper"]}>
            <Link
              to={`${i18n.language === "hy" ? "" : i18n.language}/`}
              className={styles["logo-link"]}>
              <Box
                width={50}
                height={40}
                component="img"
                src={logo}
                alt="logo"
                className={styles["logo"]}
              />

              <span className={styles["logo-name"]}>Bedding shop</span>
            </Link>
          </Typography>
        </div>

        <div className={"socialBar"}>
          <IconButton
            onClick={() =>
              handleSocialIconClick("https://www.facebook.com/bedder.shop")
            }>
            <FaFacebookF color={"#fff"} />
          </IconButton>
          <IconButton
            onClick={() =>
              handleSocialIconClick("https://www.instagram.com/beddingshop.am/")
            }>
            <FaInstagram color={"#fff"} />
          </IconButton>
          <IconButton
            onClick={() =>
              handleSocialIconClick(
                "https://www.youtube.com/@BeddingShopam/featured"
              )
            }>
            <FaYoutube color={"#fff"} />
          </IconButton>
          <IconButton
            onClick={() => handleSocialIconClick("https://t.me/BeddingShopam")}>
            <FaTelegramPlane color={"#fff"} />
          </IconButton>
          <IconButton
            onClick={() =>
              handleSocialIconClick(
                "https://api.whatsapp.com/send?phone=37499111040"
              )
            }>
            <FaWhatsapp color={"#fff"} />
          </IconButton>
        </div>

        <div
          className={"row-custom"}
          style={{ gap: 0 }}>
          <Language />
          {token && (
            <Box className={styles["user-options"]}>
              {jwtDecode(token).role === "ADMIN" && (
                <Button
                  variant="outline-light"
                  onClick={admin_panel}>
                  {t("Auth.AdminPanel")}
                </Button>
              )}
              <Button
                variant="outline-light"
                onClick={logOut}
                className={`${styles["ml-2"]} ${styles["flex-row"]}`}>
                {t("Auth.LogOut")}
              </Button>
            </Box>
          )}
          {!token && (
            <Box className={styles["user-options"]}>
              <Button
                variant="outline-light"
                onClick={login}>
                {t("Global.Login/Register")}
              </Button>
            </Box>
          )}

          <Box
            className={`${styles["icon-wrapper"]} ${styles["ml-2"]} ${styles["flex-row"]}${styles["outline-light"]}`}
            onClick={basket_link}>
            {myItems && (
              <span className={styles["count"]}>{myItems.length}</span>
            )}
            <ShoppingCartOutlinedIcon className={styles["icon-card"]} />
          </Box>
        </div>
      </Toolbar>
    </AppBar>
  );
});

export default NevNavbar;
