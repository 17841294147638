import React, { useState } from "react";
import { createType } from "../../http/deviceAPI";
import { Button, Form, Modal } from "react-bootstrap";

const CreateType = ({ show, onHide }) => {
  const [name, setName] = useState({ en: "", hy: "", ru: "" });

  const handleInputChange = (language, value) => {
    setName((prevName) => ({
      ...prevName,
      [language]: value,
    }));
  };

  const addType = () => {
    if (!name.en) {
      alert("English name is required!");
      return;
    }

    const translations = [
      { language: "en", name: name.en },
      { language: "hy", name: name.hy },
      { language: "ru", name: name.ru },
    ];

    // Pass the object directly instead of stringifying it
    createType(name, translations)
      .then((data) => {
        setName({ en: "", hy: "", ru: "" });
        onHide();
      })
      .catch((error) => {
        console.error("Error during type creation:", error);
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить тип
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            value={name.en}
            onChange={(e) => handleInputChange("en", e.target.value)}
            placeholder="Enter type name in English"
          />
          <Form.Control
            value={name.hy}
            onChange={(e) => handleInputChange("hy", e.target.value)}
            placeholder="Մուտքագրեք տեսակի անվանումը հայերեն"
            className="mt-2"
          />
          <Form.Control
            value={name.ru}
            onChange={(e) => handleInputChange("ru", e.target.value)}
            placeholder="Введите название типа на русском"
            className="mt-2"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={onHide}>
          Закрыть
        </Button>
        <Button
          variant={"outline-success"}
          onClick={addType}>
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateType;
