import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import { useNavigate } from "react-router-dom";
import "../components/LeftTypeBar.css";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { slugify } from "transliteration";

const LeftTypeBar = observer(({ setIsOpen, isOpen }) => {
  const { i18n } = useTranslation("global");
  const { device } = useContext(Context);
  const navigate = useNavigate();

  const handleTypeClick = (type) => {
    device.setSelectedType(type);
    const currentLang = i18n.language === "hy" ? "" : `/${i18n.language}`;

    const latinName = slugify(type.name, { lowercase: false });
    const newPath = `${currentLang}/products/${type.id}/${encodeURIComponent(
      latinName
    ).toLowerCase()}`;
    navigate(newPath);
  };

  return (
    <div
      className={`menuBackground${isOpen ? " isOpen" : ""}`}
      onClick={() => setIsOpen(false)}>
      <ul className="menu">
        {device?.types.map((type) => {
          return (
            <MenuItem
              className="item"
              onClick={() => handleTypeClick(type)}
              key={type.id}>
              <div className="linkStyle">
                <span className="nameStyle">
                  {type?.translations?.[i18n.language]?.name}
                </span>
              </div>
            </MenuItem>
          );
        })}
      </ul>
    </div>
  );
});

export default LeftTypeBar;
