import React, { createContext } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import UserStore from "./store/UserStore";
import DeviceStore from "./store/DeviceStore";
import "./index.css";
import { CartProvider } from "./CartContext";

export const Context = createContext(null);

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Context.Provider
      value={{
        user: new UserStore(),
        device: new DeviceStore(),
      }}>
      <CartProvider>
        <App />
      </CartProvider>
    </Context.Provider>
  </React.StrictMode>
);

// #289b41
