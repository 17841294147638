import React from "react";
import styles from "./ContactUs.module.css";
import qrFacebook from "../../assets/qrFacebook.png";
import qrInsta from "../../assets/qrInsta.png";
import qrYoutube from "../../assets/qrYoutube.png";
import qrTelegram from "../../assets/qrTelegram.png";
import qrWhatsUp from "../../assets/qrWhatsUp.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTelegramPlane,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const qrCodes = [
  {
    img: qrFacebook,
    text: "Facebook",
    icon: FaFacebookF,
    url: "https://www.facebook.com/bedder.shop",
  },
  {
    img: qrInsta,
    text: "Instagram",
    icon: FaInstagram,
    url: "https://www.instagram.com/beddingshop.am/",
  },
  {
    img: qrYoutube,
    text: "Youtube",
    icon: FaYoutube,
    url: "https://www.youtube.com/@BeddingShopam/featured",
  },
  {
    img: qrTelegram,
    text: "Telegram",
    icon: FaTelegramPlane,
    url: "https://t.me/BeddingShopam",
  },
  {
    img: qrWhatsUp,
    text: "WhatsUp",
    icon: FaWhatsapp,
    url: "https://api.whatsapp.com/send?phone=37499111040",
  },
];
const ContactUs = () => {
  const { t } = useTranslation("global");
  const handleSocialIconClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className={"wrapper"}>
      <h2 style={{ textAlign: "center", marginTop: 20 }}>
        {t("Global.ContactUs")}
      </h2>
      <div className={styles.ContactUs}>
        {qrCodes.map((item) => (
          <Link
            className={styles.qr}
            onClick={() => handleSocialIconClick(item.url)}>
            <img
              src={item.img}
              alt={item.img}
              width={"100%"}
            />
            <div className={styles.textIcon}>
              <item.icon fontSize={40} />
              <h2 className={styles.text}>{item.text}</h2>
            </div>
          </Link>
        ))}
      </div>
      <h2 style={{ textAlign: "center", marginTop: 30 }}>
        {t("AboutUs.PhoneNumber")}
      </h2>

      <div style={{ textAlign: "center" }}>
        <Link
          to={"tel:099-111-040"}
          style={{ color: "black", fontSize: 30 }}>
          099-111-040
        </Link>
      </div>
    </div>
  );
};

export default ContactUs;
