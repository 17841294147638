import { Grid } from "@mui/material";
import React from "react";
import styles from "../Trait/TriaitStyles.module.scss";
import {
  RocketLaunchRounded,
  RotateLeftRounded,
  ErrorOutlineRounded,
} from "@mui/icons-material";
import {
  FaRegLifeRing,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa";

import backgroundImage from "../../assets/backgrount_.webp";
import { useTranslation } from "react-i18next";

const Trait = () => {
  const { t } = useTranslation("global");
  const handleFacebookClick = () => {
    window.open("https://www.facebook.com/bedder.shop", "_blank");
  };

  const handleTelegramClick = () => {
    window.open("https://t.me/BeddingShopam", "_blank");
  };

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/beddingshop.am/", "_blank");
  };

  const handleYoutubeClick = () => {
    window.open("https://www.youtube.com/@BeddingShopam/featured", "_blank");
  };

  const handleWhatsAppClick = () => {
    window.open("https://api.whatsapp.com/send?phone=37499111040", "_blank");
  };

  return (
    <div className={"wrapper"}>
      <div style={{ marginBottom: "1rem" }}>
        <div className={styles.icons}>
          <div className={styles.icon}>
            <RocketLaunchRounded sx={{ width: "70px", fontSize: "3rem" }} />
            <div className={styles.box}>
              <h3>{t("Global.FreeDeliveryToYerevan")}</h3>
              <p>{t("Global.DeliveryToRegion")}</p>
            </div>
          </div>
          <div className={styles.icon}>
            <RotateLeftRounded sx={{ width: "70px", fontSize: "3rem" }} />
            <div className={styles.box}>
              <h3>{t("Global.FreeReturnOrExchange")}</h3>
              <p>{t("Global.ReturnOrExchangeTime")}</p>
            </div>
          </div>
          <div className={styles.icon}>
            <ErrorOutlineRounded sx={{ width: "70px", fontSize: "3rem" }} />
            <div className={styles.box}>
              <h3>{t("Global.GetDiscounts!")}</h3>
              <p>{t("Global.WhenBuyingMultipleProducts")}</p>
            </div>
          </div>
          <div className={styles.icon}>
            <FaRegLifeRing style={{ width: "70px", fontSize: "2.5rem" }} />
            <div className={styles.box}>
              <h3>{t("Global.WeWorkEveryDay")}</h3>
              <p>{t("Global.WorkingTime")}</p>
            </div>
          </div>
        </div>
        <div>
          <div>
            <Grid
              spacing={1}
              className={styles.bgBG}>
              <img
                src={backgroundImage}
                alt="Badding Shop"
                key="Badding Shop"
                className={styles.socialBackground}
              />

              <div className={styles.gridContent}>
                <Grid
                  item
                  lg={6}
                  className={styles.grid}>
                  <h1>{t("Global.WeAreOnSocialNetworks")}</h1>
                  <p></p>
                  <div className={styles.socialIcons}>
                    <div onClick={handleFacebookClick}>
                      <FaFacebookF />
                    </div>
                    <div onClick={handleTelegramClick}>
                      <FaTelegram />
                    </div>
                    <div onClick={handleInstagramClick}>
                      <FaInstagram />
                    </div>
                    <div onClick={handleYoutubeClick}>
                      <FaYoutube />
                    </div>
                    <div onClick={handleWhatsAppClick}>
                      <FaWhatsapp />
                    </div>
                  </div>
                </Grid>
              </div>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trait;
