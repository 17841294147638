import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Basket from "../pages/Basket/Basket";
import Admin from "../pages/Admin";
import Auth from "../pages/Auth";
import DevicePage from "../pages/Product/DevicePage";
import BaddingItems from "../pages/Product/badding-items";
import Shop from "../pages/Home/Shop";
import About from "../pages/About";
import ProductsByTypePage from "../components/ProductsByTypePage";
import { useTranslation } from "react-i18next";
import ContactUs from "../pages/ContactUs/ContactUs";

const AppRouter = () => {
  const { i18n } = useTranslation("global");
  const token = localStorage.getItem("token");

  return (
    <Routes>
      <Route path={":lang?"}>
        <Route
          exact
          path=""
          element={<Shop />}
        />
        <Route
          path="basket"
          element={<Basket />}
        />
        <Route
          path="login"
          element={<Auth />}
        />
        <Route
          path="registration"
          element={<Auth />}
        />
        <Route
          path="ankoxnayin-paraga/:id/:name"
          element={<DevicePage />}
        />
        <Route
          path="ankoxnayin-paraganer"
          element={<BaddingItems />}
        />
        <Route
          path="mer-masin"
          element={<About />}
        />
        <Route
          path="products/:id/:name"
          element={<ProductsByTypePage />}
        />
        <Route
          path="contact-us"
          element={<ContactUs />}
        />
        {token && (
          <Route
            path="admin"
            element={<Admin />}
          />
        )}
{/* 
        <Route
          path="*"
          element={
            <Navigate to={i18n.language === "hy" ? "" : i18n.language} />
          }
        /> */}
      </Route>
    </Routes>
  );
};

export default AppRouter;
