import { $authHost, $host } from "./index";

// Create a type with translations
export const createType = async (name, translations) => {
  console.log("📢 [deviceAPI.js:5] Name being sent:", name, translations);

  try {
    // Directly pass the object, no need for JSON.stringify here.
    const { data } = await $authHost.post("/api/type", {
      name: name.en,
      translations: translations,
    });
    return data;
  } catch (error) {
    console.error(
      "Error creating type:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Fetch all types
export const fetchTypes = async () => {
  try {
    const { data } = await $host.get("api/type");
    return data;
  } catch (error) {
    console.error(
      "Error fetching types:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Create a brand with translations
export const createBrend = async (name, translations) => {
  try {
    const { data } = await $authHost.post("api/brand", {
      name: name.en,
      translations: translations,
    });
    console.log("📢 [deviceAPI.js:43]", data);
    return data;
  } catch (error) {
    console.error(
      "Error creating brand:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Fetch all brands
export const fetchBrends = async () => {
  try {
    const { data } = await $host.get("api/brand");
    return data;
  } catch (error) {
    console.error(
      "Error fetching brands:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Create a device with images and translations
export const createDevice = async (device) => {
  const { data } = await $authHost.post("api/device", device);
  return data;
};

export const fetchDevice = async (typeId, brendId, page, limit = 5) => {
  try {
    const { data } = await $host.get("api/device", {
      params: {
        typeId,
        brendId,
        page,
        limit,
      },
    });
    return data;
  } catch (error) {
    console.error(
      "Error fetching devices:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Fetch a single device by ID
export const fetchOneDevice = async (id) => {
  try {
    const { data } = await $host.get(`api/device/${id}`);
    return data;
  } catch (error) {
    console.error(
      "Error fetching device:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Update a device by ID
export const updateDevice = async (id, deviceData) => {
  try {
    const { data } = await $authHost.put(`api/device/${id}`, deviceData);

    console.log("📢 [deviceAPI.js:147]", data);
    return data;
  } catch (error) {
    console.error(
      "Failed to update device:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Delete a device by ID
export const deleteOne = async (id) => {
  try {
    const { data } = await $authHost.delete(`api/device/${id}`);
    return data;
  } catch (error) {
    console.error(
      "Error deleting device:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Delete a type by ID
export const deleteOneType = async (id) => {
  try {
    const { data } = await $authHost.delete(`api/type/${id}`);
    return data;
  } catch (error) {
    console.error(
      "Error deleting type:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Delete a brand by ID
export const deleteOneBrand = async (id) => {
  try {
    const { data } = await $authHost.delete(`api/brand/${id}`);
    return data;
  } catch (error) {
    console.error(
      "Error deleting brand:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Fetch devices filtered by type and brand
export const fetchDevicesByType = async (typeId, brendId, page, limit = 5) => {
  try {
    const { data } = await $host.get("api/device", {
      params: { typeId, brendId, page, limit },
    });
    return data;
  } catch (error) {
    console.error(
      "Error fetching devices by type:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const deleteImage = async (imageId) => {
  try {
    const response = await $authHost.delete(
      `${process.env.REACT_APP_API_URL}/api/device/${imageId}/images`
    );
    console.log(response.data.message); // Log success message
    return true; // Indicate success
  } catch (error) {
    console.error("Failed to delete image:", error);
    return false; // Indicate failure
  }
};
