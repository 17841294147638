import { ArrowRightAlt } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Styles.module.scss";
import slide1 from "../../../../../src/img/slide1.jpg";
import slide2 from "../../../../../src/img/slider 2.jpg";
import slide3 from "../../../../../src/img/slider 3 n.jpg";
import { useTranslation } from "react-i18next";

const SideBanner = () => {
  const { t, i18n } = useTranslation("global");
  return (
    <div className={styles.side}>
      <div
        style={{
          display: "flex",
          position: "relative",
          background: "#fdefd6",
        }}>
        <div className={styles.sideBannerText}>
          <h4>{t("Global.TopProduct")}</h4>
          <h3>{t("Global.BlanketsSummer")}</h3>
          <Link
            to={`${
              i18n.language === "hy" ? "" : i18n.language
            }/products/9/vermakner-amarhayin`}>
            {t("Global.BuyNow")} <ArrowRightAlt style={{ fontSize: 22 }} />
          </Link>
        </div>
        <img
          src={slide1}
          alt="banner"
        />
      </div>
      <div style={{ display: "flex", position: "relative" }}>
        <div className={styles.sideBannerText}>
          <h4 style={{ marginBottom: 0 }}>{t("Global.TopProduct")}</h4>
          <h3 style={{ marginBottom: 0 }}>{t("Global.Pillows")}</h3>
          <p style={{ lineHeight: 1.6 }}>{t("Global.SaveUp")} ֏500</p>
          <Link
            to={`${
              i18n.language === "hy" ? "" : i18n.language
            }/products/8/bardzer`}>
            {t("Global.BuyNow")}{" "}
            <ArrowRightAlt style={{ ml: 1, fontSize: 22 }} />
          </Link>
        </div>
        <img
          src={slide2}
          alt="banner"
        />
      </div>
      <div
        style={{
          display: "flex",
          // backgroundColor: "#f0f0f0",
          position: "relative",
        }}>
        <div className={styles.sideBannerText}>
          <h4 style={{ marginBottom: 0 }}>{t("Global.TopProduct")}</h4>
          <h3 style={{ marginBottom: "0.2rem" }}>{t("Global.Covers")}</h3>
          <p style={{ lineHeight: 1 }}>{t("Global.HottestOffer")}</p>
          <Link
            to={`${
              i18n.language === "hy" ? "" : i18n.language
            }/products/12/tsatskots`}>
            {t("Global.BuyNow")}{" "}
            <ArrowRightAlt style={{ ml: 1, fontSize: 22 }} />
          </Link>
        </div>
        <img
          src={slide3}
          alt="banner"
        />
      </div>
    </div>
  );
};

export default SideBanner;
