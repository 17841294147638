import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import styles from "./Styles.module.scss";
import { ArrowRightAlt } from "@mui/icons-material";
import { Link } from "react-router-dom";
import slide1 from "../../../../../src/img/slider 780 2.jpg";
import slide2 from "../../../../../src/img/slider  2 n.jpg";
import { useTranslation } from "react-i18next";

const HeaderBanner = () => {
  const { t, i18n } = useTranslation("global");
  return (
    <Swiper
      navigation={true}
      modules={[Navigation]}
      style={{ height: "100% !important", position: "relative" }}
      className={`${styles.swiper} swiperC`}>
      <SwiperSlide
        className={styles["swiper-slide"]}
        position="relative">
        <div className={styles.headerSliderText}>
          <h3>{t("Global.BestSelling")}</h3>
          <h1>{t("Global.Bedding")}</h1>
          <p>
            <sup className={styles.firstSup}>{t("Global.Since")}</sup>
            <span>
              ֏ 2999<sup className={styles.secondSup}>․99</sup>
            </span>
          </p>
          <Link
            to={`${
              i18n.language === "hy" ? "" : i18n.language
            }/products/18/carolina-mekteghanots`}
            className={styles.bannerButton}>
            {t("Global.SeeAll")} <ArrowRightAlt sx={{ ml: 1, fontSize: 22 }} />
          </Link>
        </div>
        <img
          className={styles.photo1}
          src={slide1}
          alt={""}
        />
      </SwiperSlide>
      <SwiperSlide
        className={styles["swiper-slide"]}
        position="relative">
        <div className={styles.headerSliderText}>
          <h3>{t("Global.HotelSatin")}</h3>
          <h1>{t("Global.Bedding")}</h1>
          <p>
            <sup
              className={styles.firstSup}
              style={{ textDecoration: "line-through", color: "#333" }}>
              ֏7000
            </sup>
            <span>
              ֏ 2999
              <sup
                className={styles.secondSup}
                style={{ top: "-0.2em" }}>
                .99
              </sup>
            </span>
          </p>
          <Link
            to={`${
              i18n.language === "hy" ? "" : i18n.language
            }/products/10/zhat-erkteghanots`}
            className={styles.bannerButton}>
            {t("Global.SeeAll")} <ArrowRightAlt sx={{ ml: 1, fontSize: 22 }} />
          </Link>
        </div>
        <img
          className={styles.photo2}
          src={slide2}
          alt={""}
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default HeaderBanner;
