import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Style.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useCart } from "../../CartContext";
import { useTranslation } from "react-i18next";

const MainPage = () => {
  const { t, i18n } = useTranslation("global");
  const { busketItems: basketProducts, removeFromCart } = useCart();
  const [sortBy, setSortBy] = useState("price");
  const [values, setValues] = useState({
    name: "",
    address: "",
    phoneNumber: "",
  });
  const [errMessage, setErrMessage] = useState({
    name: "",
    address: "",
    phoneNumber: "",
  });

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setValues((prev) => {
      return { ...prev, [name]: value };
    });
    setErrMessage((prev) => {
      return { ...prev, [name]: "" };
    });
  };
  // const sortBasketProducts = () => {
  //   switch (sortBy) {
  //     case "price":
  //       return [...basketProducts].sort((a, b) => a.product.price - b.product.price);
  //     case "name":
  //       return [...basketProducts].sort((a, b) => a.product.name.localeCompare(b.product.name));
  //     default:
  //       return basketProducts;
  //   }
  // };

  const calculateTotalPrice = () => {
    return basketProducts?.reduce(
      (total, item) => total + item.product.price * item.amount,
      0
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const handleCheckout = async () => {
  //   const { name, address } = values;

  //   const phoneNumber = values.phoneNumber;
  //   const phoneNumberRegex = /^\d{9}$/;

  //   let valid = true;

  //   const errMessage = {
  //     name: "",
  //     address: "",
  //     phoneNumber: "",
  //   };

  //   if (!name.trim()) {
  //     errMessage.name = "Անունը պարտադիր է";
  //     valid = false;
  //   }

  //   if (!address.trim()) {
  //     errMessage.address = "Հասցեն պարտադիր է";
  //     valid = false;
  //   }

  //   if (!phoneNumber.trim()) {
  //     errMessage.phoneNumber = "Հեռախոսահամարը պարտադիր է";
  //     valid = false;
  //   } else if (!phoneNumberRegex.test(phoneNumber)) {
  //     errMessage.phoneNumber = "Հեռախոսահամարը պետք է ունենա 9 թիվ";
  //     valid = false;
  //   }
  //   // if (!/^\+374 \d{2} \d{2} \d{2} \d{2}$/.test(phoneNumber)) {
  //   //   errMessage.phoneNumber =  "Սխալ հեռախոսահամարի ձևաչափ";
  //   //   valid = false;
  //   // }

  //   setErrMessage(errMessage);
  //   if (valid) {
  //     const orderDetails = {
  //       basketProducts,
  //       totalPrice: calculateTotalPrice(),
  //       name,
  //       address,
  //       phoneNumber,
  //     };
  //     try {
  //       // Отправка данных на бэкенд
  //       await axios.post(
  //         `${process.env.REACT_APP_API_URL}api/submit-order`,
  //         orderDetails
  //       );
  //       // Отображение успешного сообщения или выполнение других действий
  //       alert(
  //         `Ձեր պատվերը ընդունվել է! Հարգելի ${name},  Մեր մասնագետները Ձեզ հետ կկապնվեն Շնորհակալություն`
  //       );
  //       // localStorage.removeItem('busketItems');
  //       removeFromCart();
  //       setValues({
  //         name: "",
  //         address: "",
  //         phoneNumber: "",
  //       });
  //     } catch (error) {
  //       console.error("Error sending order:", error);
  //     }
  //   }
  // };

  const handleCheckout = async () => {
    const { name, address } = values;
    const phoneNumber = values.phoneNumber;
    const phoneNumberRegex = /^\d{9}$/;

    let valid = true;

    const errMessage = {
      name: "",
      address: "",
      phoneNumber: "",
    };

    if (!name.trim()) {
      errMessage.name = "Անունը պարտադիր է";
      valid = false;
    }

    if (!address.trim()) {
      errMessage.address = "Հասցեն պարտադիր է";
      valid = false;
    }

    if (!phoneNumber.trim()) {
      errMessage.phoneNumber = "Հեռախոսահամարը պարտադիր է";
      valid = false;
    } else if (!phoneNumberRegex.test(phoneNumber)) {
      errMessage.phoneNumber = "Հեռախոսահամարը պետք է ունենա 9 թիվ";
      valid = false;
    }

    setErrMessage(errMessage);
    if (valid) {
      const transformedBasketProducts = basketProducts.map((item) => ({
        ...item,
        product: {
          ...item.product,
          img: item.product.img.map((img) => img.path),
        },
      }));

      const orderDetails = {
        basketProducts: transformedBasketProducts,
        totalPrice: calculateTotalPrice(),
        name,
        address,
        phoneNumber,
      };

      try {
        await axios.post(
          `https://api.beddingshop.am/api/submit-order`,
          orderDetails
        );
        alert(
          `Ձեր պատվերը ընդունվել է! Հարգելի ${name},  Մեր մասնագետները Ձեզ հետ կկապնվեն Շնորհակալություն`
        );
        removeFromCart();
        setValues({
          name: "",
          address: "",
          phoneNumber: "",
        });
      } catch (error) {
        console.error("Error sending order:", error);
      }
    }
  };

  return (
    <>
      <section
        className="h-100 h-custom"
        style={{ backgroundColor: "#eee" }}>
        <MDBContainer className="py-5 h-100">
          <MDBRow className="justify-content-center align-items-center h-100">
            <MDBCol>
              <MDBCard>
                <MDBCardBody className="p-4">
                  <MDBRow>
                    <MDBCol lg="7">
                      <MDBTypography tag="h5">
                        <Link
                          to={`${i18n.language === "hy" ? "" : i18n.language}/`}
                          className="text-body">
                          <MDBIcon
                            fas
                            icon="long-arrow-alt-left me-2"
                          />
                          {t("Basket.ContinueShopping")}
                        </Link>
                      </MDBTypography>
                      <hr />

                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                          <p className="mb-1">{t("Basket.ShoppingCart")}</p>
                          <p className="mb-0">
                            {t("Basket.BasketProductCount", {
                              count: basketProducts?.length || 0,
                            })}
                          </p>
                        </div>
                        <div>
                          <div style={{ minWidth: 84 }}>
                            <p className="mb-0">
                              <span className="text-muted">
                                {t("Basket.SortBy")}
                              </span>
                              <select
                                value={sortBy}
                                onChange={handleSortChange}
                                className="form-select form-select-sm">
                                <option value="price">
                                  {t("Basket.Price")}
                                </option>
                                <option value="name">{t("Basket.Name")}</option>
                                {/* Add more options for other sorting options */}
                              </select>
                            </p>
                          </div>
                        </div>
                      </div>

                      <MDBCard className="mb-3">
                        {basketProducts?.length === 0 ? (
                          <MDBCardBody>
                            <p>{t("Global.YourShoppingCartIsEmpty")}</p>
                          </MDBCardBody>
                        ) : (
                          basketProducts?.map((item) => {
                            const img =
                              typeof item.product?.img === "string"
                                ? JSON.parse(item.product.img)
                                : item.product?.img;

                            console.log(img[0]?.path);

                            return (
                              <MDBCardBody key={item.product.id}>
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-row align-items-center">
                                    <div>
                                      <MDBCardImage
                                        src={
                                          process.env.REACT_APP_API_URL +
                                          img[0]?.path
                                        }
                                        fluid
                                        className="rounded-3"
                                        style={{ width: "65px" }}
                                        alt="Shopping item"
                                      />
                                    </div>
                                    <div className="ms-3">
                                      <MDBTypography tag="h6">
                                        {item.product.name}
                                      </MDBTypography>
                                      <p className="small mb-0"></p>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row align-items-center">
                                    <div style={{ width: "50px" }}>
                                      <MDBTypography
                                        tag="h5"
                                        className="fw-normal mb-0">
                                        {item.amount}
                                      </MDBTypography>
                                    </div>
                                    <div style={{ width: "80px" }}>
                                      <MDBTypography
                                        tag="h6"
                                        className="mb-0">
                                        ֏ {item.product.price}
                                      </MDBTypography>
                                    </div>
                                    <a
                                      href="#!"
                                      style={{ color: "#cecece" }}>
                                      <MDBIcon
                                        fas
                                        icon="trash-alt"
                                      />
                                      <Button
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        onClick={() =>
                                          removeFromCart(item.product.id)
                                        }
                                        style={{ border: "none" }}>
                                        <div className="delete">
                                          {t("Global.Delete")}
                                        </div>
                                      </Button>
                                    </a>
                                  </div>
                                </div>
                              </MDBCardBody>
                            );
                          })
                        )}
                      </MDBCard>
                    </MDBCol>

                    <MDBCol lg="5">
                      <MDBCard
                        style={{ backgroundColor: "#289b41" }}
                        className="text-white rounded-3">
                        <MDBCardBody>
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <MDBTypography
                              style={{ textTransform: "uppercase" }}
                              tag="h5"
                              className="mb-0">
                              {t("Basket.RegisterANewOrder")}
                            </MDBTypography>
                          </div>

                          <a
                            href="#!"
                            type="submit"
                            className="text-white">
                            <MDBIcon
                              fab
                              icon="cc-mastercard fa-2x me-2"
                            />
                          </a>
                          <a
                            href="#!"
                            type="submit"
                            className="text-white">
                            <MDBIcon
                              fab
                              icon="cc-visa fa-2x me-2"
                            />
                          </a>
                          <a
                            href="#!"
                            type="submit"
                            className="text-white">
                            <MDBIcon
                              fab
                              icon="cc-amex fa-2x me-2"
                            />
                          </a>
                          <a
                            href="#!"
                            type="submit"
                            className="text-white">
                            <MDBIcon
                              fab
                              icon="cc-paypal fa-2x me-2"
                            />
                          </a>

                          <form className="mt-4">
                            {t("Basket.NameAndSurname")}
                            <span style={{ color: "red" }}>
                              {errMessage.name}
                            </span>
                            <MDBInput
                              required={true}
                              className="mb-4"
                              type="text"
                              size="lg"
                              name="name"
                              onChange={handleChange}
                              value={values.name}
                              // onBlur={() => {
                              //   if (!name.trim()) {
                              //     setNameError("Անունը պարտադիր է");
                              //   } else {
                              //     setNameError("");
                              //   }
                              // }}
                              contrast
                            />
                            {t("Basket.Address")} *
                            <span style={{ color: "red" }}>
                              {errMessage.address}
                            </span>
                            <MDBInput
                              className="mb-4"
                              type="text"
                              size="lg"
                              name="address"
                              onChange={handleChange}
                              value={values.address}
                              // onBlur={() => {
                              //   if (!address.trim()) {
                              //     setAddressError("Հասցեն պարտադիր է");
                              //   } else {
                              //     setAddressError("");
                              //   }
                              // }}
                              error={errMessage.address} // Show the error message using the error prop
                              contrast
                            />
                            {t("Basket.PhoneNumber")}
                            <span style={{ color: "red" }}>
                              {errMessage.phoneNumber}
                            </span>
                            <MDBInput
                              placeholder="099000000"
                              className="mb-4"
                              type="text"
                              size="lg"
                              name="phoneNumber"
                              onChange={handleChange}
                              value={values.phoneNumber}
                              // onBlur={() => {
                              //   if (!phoneNumber.trim()) {
                              //     setPhoneNumberError("Հեռախոսահամարը պարտադիր է");
                              //   } else if (!/^\+374 \d{2} \d{2} \d{2} \d{2}$/.test(phoneNumber)) {
                              //     setPhoneNumberError("Սխալ հեռախոսահամարի ձևաչափ");
                              //   } else {
                              //     setPhoneNumberError("");
                              //   }
                              // }}
                              error={errMessage.phoneNumber} // Show the error message using the error prop
                              contrast
                            />
                          </form>

                          <hr />

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">{t("Basket.Subtotal")}</p>
                            <p className="mb-2">{calculateTotalPrice()} ֏</p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">
                              {t("Basket.DeliveryToYerevan")}
                            </p>
                            <p className="mb-2"> 0 ֏ ({t("Basket.Free")})</p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">
                              {t("Basket.DeliveryInTheRegions")}
                            </p>
                            <p className="mb-2">{t("Basket.StartingFrom")}</p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">{t("Basket.Total")}</p>
                            <p className="mb-2">{calculateTotalPrice()} ֏</p>
                          </div>

                          <button
                            onClick={handleCheckout}
                            className="d-flex justify-content-between button">
                            <span>{calculateTotalPrice()} ֏ </span>
                            <i className="fas fa-long-arrow-alt-right ms-2"></i>
                            <span
                              onClick={handleCheckout}
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}>
                              {t("Basket.Order")}
                            </span>
                          </button>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </>
  );
};

export default MainPage;
