import React from "react";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Context } from "../index";
import { Card } from "react-bootstrap";

const BrandBar = observer(() => {
  const { device } = useContext(Context);
  return (
    <div
      className="row-custom"
      style={{ gap: 10, marginTop: 10, flexWrap: "wrap" }}>
      {device &&
        device.brends.map(
          (brend) =>
            brend.name && (
              <Card
                style={{ cursor: "pointer" }}
                key={brend.id}
                className={`p-2 w-auto ${
                  brend.id === device.selectedBrend.id ? "focus-card" : ""
                }`}
                onClick={() => device.setSelectedBrend(brend)}
                border={
                  brend.id === device.selectedBrend.id ? "green" : "light"
                }>
                {brend.name}
              </Card>
            )
        )}
    </div>
  );
});

export default BrandBar;
