import React from "react";
import DeviceList from "../../components/DeviceList";
import "./BaddingItems.css";
import { Col } from "react-bootstrap";
import Pages from "../../components/Pages";

const BaddingItems = () => {
  return (
    <div className={"wrapper"}>
      <div className="badding-items-container">
        <Col md={12}>
          <DeviceList />
          <Pages />
        </Col>
      </div>
    </div>
  );
};

export default BaddingItems;
